class SubscribeBox extends Component {
	constructor(el) {
		super(el);
		this.form = this.$find('form');
		this.addListeners();
	}

	onSubmit(e) {
		e.preventDefault();
		EventBus.getInstance().dispatchEvent('showGlobalSpinner');

		let formData = new FormData(this.form);
		formData.append('ajax', '1');
		formData.append(this.form.querySelector('[data-button-submit]').getAttribute('name'), '1');

		fetch(this.form.getAttribute('action'), {
			method: 'POST',
			body: formData,
		})
		.then(response => response.json())
		.then(data => this.onSuccess(data))
		.catch((error) => {
			console.error('Error:', error);
		}).finally(() => {
			EventBus.getInstance().dispatchEvent('hideGlobalSpinner');
		});
	}

	onSuccess(data) {
		if (data.result === true) {
			EventBus.getInstance().dispatchEvent('showBasePopup', 'newsletter-popup');
		}
		if(data.remarketingCodes) {
			const scriptTemplate = document.createElement('template');
			scriptTemplate.innerHTML = data.remarketingCodes;
			document.body.appendChild(scriptTemplate.content.firstElementChild);
		}
	}

	addListeners() {
		this.form.addEventListener('submit', (e) => this.onSubmit(e));
	}

}
